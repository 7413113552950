<template>
    <div>
        <app-menu/>
            <v-tabs v-model="sucursal" >
                <v-tabs-slider color="yellow"></v-tabs-slider> 
                <v-tabs v-model="sucursal" background-color="primary" color="basil" grow dark >
                    <v-tab>Sucursales</v-tab>
                </v-tabs>
            </v-tabs>
            <v-card min-height="850px">
                <CEsucursal />
            </v-card>
    </div> 
</template>

<script>
import AppMenu from '../../components/admin/MenuAdmin.vue'
import CEsucursal from '../../components/admin/CEsucursal.vue'

export default{
    name: "HomeAdmin",
    data(){
        return{
            sucursal:"",
            verfiltro:false,

        }
    },
    components:{
        'app-menu': AppMenu,
        CEsucursal
    }
}
</script>
