<template>
    <div>
        <v-container>
            <v-card >
                <v-card-title>
                    <v-text-field v-model="search"  prepend-icon="mdi-store-search-outline" label="Buscar sucursal"></v-text-field>
                </v-card-title>
                <div class="container">
                    <v-btn
                    text
                    color="blue"
                    dark
                    class="mb-2"
                    @click="addSucursal=true"
                    ><v-icon color="#40E0D0" style="font-size:29px;">mdi-store-settings-outline</v-icon>
                    Agregar Sucursal
                    </v-btn>
                </div>
                <v-dialog max-width="620px" v-model="addSucursal">
                    <v-card>
                        <v-card dark  color="blue lighten-1" style="border-radius:0px 0px 180px 0px;">
                            <v-card-title class="text-h4">Agregar sucursal</v-card-title>
                        </v-card>
                        <v-card-text>
                            <v-container>
                                <v-form ref="agregarform">
                                    <v-row >
                                        <v-col
                                            sm="6"
                                            md="12"
                                        ><v-text-field
                                            v-model="agregarItem.name"
                                            label="Nombre de la Sucursal"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                            sm="6"
                                            md="7">
                                            <v-text-field
                                            v-model="agregarItem.ad_org_id"
                                            label="Organización"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            sm=""
                                            md="5">
                                            <v-radio-group v-model="agregarItem.isactive" row :rules="[(v) => !!v || 'Es requerido' ]">
                                                <v-card-title class="" style="font-size:15px; color:#00A0F6;">Activo</v-card-title> 
                                                <v-radio color="blue" :value="isactive = 'Y'" label="Si"></v-radio>
                                                <v-radio color="red" :value="isactive = 'N'" label="No"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col
                                            cols=""
                                            sm="6"
                                            md="7"
                                        ><v-text-field
                                            v-model="agregarItem.url_maps"
                                            label="Localización (URL)"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            sm="6"
                                            md="5">
                                            <v-radio-group v-model="agregarItem.isruta" row style="margin-top:auto;" >
                                                <v-card-title class="" style="font-size:15px; color:#00A0F6;">Ruta</v-card-title> 
                                                <v-radio color="blue" :value="isruta = true" label="Si"></v-radio>
                                                <v-radio color="red" :value="isruta = false" label="No"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                            v-model="agregarItem.horario"
                                            label="Horario"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-form>
                            </v-container>
                        </v-card-text>
                        <v-card-title v-if="agregarItem.isruta == true" class="" style="color:gray; font-size:35px;">Rutas</v-card-title>
                        <v-row style="justify-content: center; " v-if="agregarItem.isruta == true">
                            <v-card  elevation="9" class="mx-6 ma-4 col-xs-2" >
                                <v-img
                                    src="../../../public/card3.jpg"
                                    height="35px"
                                    width="246px"
                                    ></v-img>
                                <v-card-text class="">
                                    <v-text-field
                                    v-model="agregarItem2.numero_ruta"
                                    label="Número de ruta"
                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                    type="number"
                                    ></v-text-field>
                                    <v-text-field
                                    v-model="agregarItem2.dia_llegada"
                                    label="Día de llegada"
                                    type="number"
                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                    :prepend-icon="'mdi-truck-fast-outline'"                         
                                    ></v-text-field>
                                    <!-- @click:append-outer="editar_diaLlegada(editeditem,editeditem2)"   -->
                                    <v-text-field
                                    v-model="agregarItem2.dia_prepara"
                                    label="Día de Preparación"
                                    type="number"
                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                    :prepend-icon="'mdi-truck-check-outline'"
                                    ></v-text-field>
                                    <v-text-field
                                    v-model="agregarItem2.dia_salida"
                                    label="Día de salida"
                                    type="number"
                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                    :prepend-icon="'mdi-truck-delivery'"
                                    ></v-text-field>
                                    <v-text-field
                                    v-model="agregarItem2.hr_max"
                                    label="Horas"
                                    type="number"
                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                    :prepend-icon="'mdi-clock-time-eight-outline'"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-row>
                        <br>
                        <v-divider color="#00DCFF"></v-divider>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        
                        <v-btn
                            color="blue"
                            text
                            @click="agregarSucursales(agregarItem2)"
                        >
                            Guardar
                        </v-btn>
                        <v-btn
                            color="error darken-1"
                            text
                            @click="close,addSucursal=false"
                        >
                            Cancelar
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-data-table 
                    no-data-text="No hay datos disponibles"
                    no-results-text="No hay coincidencias de tu busqueda"
                    :footer-props="{'items-per-page-options':[10,20,30,40]}"
                    :headers="headers" :items="sucursales" :search="search">
                     <template v-slot:[`top`]>
                        <v-toolbar
                            flat
                        >
                            <v-spacer></v-spacer>
                            <v-dialog 
                            v-model="dialog"
                            width="50%"
                            max-width="1400px"
                            transition="dialog-top-transition"
                            >
                            <v-card>
                                <v-card dark  color="blue lighten-1" style="border-radius:0px 0px 180px 0px;">
                                    <v-card-title class="text-h4">Editar sucursal</v-card-title>
                                </v-card>
                                <v-card-text  >
                                    <v-container>
                                        <v-form ref="editForm1">
                                            <v-row style="">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                    v-model="editeditem.name"
                                                    label="Nombre de la Sucursal"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12"
                                                    sm="6"
                                                    md="7">
                                                    <v-text-field
                                                    v-model="editeditem.ad_org_id"
                                                    label="Organización"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col 
                                                    cols="12"
                                                    sm="6"
                                                    md="5">
                                                    <v-radio-group v-model="editeditem.isactive" row style="margin-top:auto;">
                                                        <v-card-title class="" style="font-size:15px; color:#00A0F6;">Activo</v-card-title> 
                                                        <v-radio color="blue" :value="isactive = 'Y'" label="Si"></v-radio>
                                                        <v-radio color="red" :value="isactive = 'N'" label="No"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="7"
                                                >
                                                    <v-text-field
                                                    v-model="editeditem.url_maps"
                                                    label="Localización (URL)"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col 
                                                    cols="12"
                                                    sm="6"
                                                    md="5">
                                                    <v-radio-group v-model="editeditem.isruta" row style="margin-top:auto;">
                                                        <v-card-title class="" style="font-size:15px; color:#00A0F6;">Ruta</v-card-title> 
                                                        <v-radio color="blue" :value="isruta = true" label="Si"></v-radio>
                                                        <v-radio color="red" :value="isruta = false" label="No"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                    v-model="editeditem.horario"
                                                    label="Horario"
                                                    :rules="[(v) => !!v || 'Es requerido' ]"
                                                    ></v-text-field>
                                                </v-col>
                                                
                                            </v-row>

                                        </v-form>
                                    </v-container>
                                </v-card-text>
                                <v-row>
                                    <v-col md="9">
                                        <v-card-title v-if="editeditem.isruta == true" class="" style="color:gray; font-size:35px; margin-top:-30px;">Rutas</v-card-title >
                                    </v-col>
                                    <v-col md="1"  v-if="editeditem.isruta == true" >
                                        <v-btn style="margin:0 auto;" text color="blue" @click="dialogruta = true">Nueva ruta</v-btn>
                                    </v-col>
                                    <v-row justify="center">
                                        <v-dialog v-model="dialogruta" max-width="300px"  transition="dialog-bottom-transition">
                                            
                                                <v-card  elevation="15" class="mx-6 ma-4 col-xs-2" max-width="246px">
                                                    <v-img
                                                    src="../../../public/cardruta.jpg"
                                                    height="35px"
                                                    width="246px"
                                                    ></v-img>
                                                    <v-form ref="addForm">
                                                        <v-card-text class="">
                                                            <v-text-field
                                                            v-model="nuevaRuta.numero_ruta"
                                                            label="Número de ruta"
                                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                                            type="number"
                                                            ></v-text-field>
                                                            <v-text-field
                                                            v-model="nuevaRuta.dia_llegada"
                                                            label="Día de llegada"
                                                            type="number"
                                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                                            :prepend-icon="'mdi-truck-fast-outline'"                         
                                                            ></v-text-field>
                                                            <v-text-field
                                                            v-model="nuevaRuta.dia_prepara"
                                                            label="Día de Preparación"
                                                            type="number"
                                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                                            :prepend-icon="'mdi-truck-check-outline'"
                                                            ></v-text-field>
                                                            <v-text-field
                                                            v-model="nuevaRuta.dia_salida"
                                                            label="Día de salida"
                                                            type="number"
                                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                                            :prepend-icon="'mdi-truck-delivery'"
                                                            ></v-text-field>
                                                            <v-text-field
                                                            v-model="nuevaRuta.hr_max"
                                                            label="Horas"
                                                            type="number"
                                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                                            :prepend-icon="'mdi-clock-time-eight-outline'"
                                                            ></v-text-field>
                                                        </v-card-text>
                                                        <v-divider color="yellow"></v-divider>
                                                        <v-card-actions>
                                                            <v-btn text color="blue" @click="agregarRuta(nuevaRuta)">Guardar</v-btn>
                                                        </v-card-actions>
                                                    </v-form>
                                                </v-card>
                                            

                                        </v-dialog>
                                    </v-row>
                                </v-row>
                                <v-row style="justify-content: center;" v-if="editeditem.isruta == true">
                                    <v-card  elevation="9" class="mx-6 ma-4 col-xs-2" v-for="editeditem2 in editeditem.rutas" :key="editeditem2.value" >
                                        <v-img
                                        style=""
                                        elevation=""
                                        src="../../../public/cardruta.jpg"
                                        height="35px"
                                        width="246px"
                                        ></v-img>
                                        <v-card-text >
                                            <v-text-field
                                            v-model="editeditem2.numero_ruta"
                                            label="Número de ruta"
                                            disabled
                                            type="number"
                                            ></v-text-field>
                                            <v-text-field
                                            v-model="editeditem2.dia_llegada"
                                            label="Día de llegada"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            type="number"
                                            :prepend-icon="'mdi-truck-fast-outline'"                         
                                            ></v-text-field>
                                            <!-- @click:append-outer="editar_diaLlegada(editeditem,editeditem2)"   -->
                                            <v-text-field
                                            v-model="editeditem2.dia_prepara"
                                            label="Día de Preparación"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            type="number"
                                            :prepend-icon="'mdi-truck-check-outline'"
                                            ></v-text-field>
                                            <v-text-field
                                            v-model="editeditem2.dia_salida"
                                            label="Día de salida"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            type="number"
                                            :prepend-icon="'mdi-truck-delivery'"
                                            ></v-text-field>
                                            <v-text-field
                                            v-model="editeditem2.hr_max"
                                            label="Horas"
                                            :rules="[(v) => !!v || 'Es requerido' ]"
                                            type="number"
                                            :prepend-icon="'mdi-clock-time-eight-outline'"
                                            ></v-text-field>
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                                <br>
                                <v-divider color="#00DCFF"></v-divider>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                
                                <v-btn
                                    color="blue"
                                    text
                                    @click="crudedit(editeditem,editeditem2)"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn
                                    color="error darken-1"
                                    text
                                    @click="close"
                                >
                                    Cancelar
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-toolbar>
                        </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <center>
                            <v-icon style="font-size:29px;" color="blue" @click="editItem(item)">
                                mdi-playlist-edit
                            </v-icon>
                        </center>
                    </template>
                     <template v-slot:[`item.ad_org_id`]="{ item }">
                       <center>
                           <div>
                               {{item.ad_org_id}}
                            </div>
                        </center> 
                    </template>   
                    <template v-slot:[`item.rutas`]="{ item }">
                       <center>
                           <div>
                               {{item.rutas.length}}
                            </div>
                        </center> 
                    </template>
                    <template v-slot:[`item.url_maps`]="{ item }">
                        <v-icon color="error" style="font-size: 24px">mdi-map-marker</v-icon>  
                        <a  class="text-h7" style="color:black; font-size: 15px;" :href="item.url_maps">{{item.url_maps}}</a>
                    </template>
                    <template v-slot:[`item.horario`]="{ item }">
                        <center>
                            {{item.horario}}
                        </center>
                    </template>
                    <template v-slot:[`item.value`]="{item}">
                       <center>
                           <div>
                               {{item.value}}
                            </div>
                        </center> 
                    </template>
                    <template v-slot:[`item.isactive`]="{ item }">
                     
                            <v-radio-group mandatory style="margin:0 auto; margin-left:15%;">
                                <v-radio  color="#40E0D0" v-if="item.isactive=='Y'" label="SI"></v-radio>
                                <v-radio  color="error" v-if="item.isactive=='N'" label="NO"></v-radio>
                            </v-radio-group>
                        
                    </template>
                    <template v-slot:[`item.isruta`]="{ item }">
                     
                            <v-radio-group mandatory style="margin:0 auto; margin-left:15%;">
                                <v-radio  color="#40E0D0" v-if="item.isruta==true" label="Activa"></v-radio>
                                <v-radio  color="error" v-if="item.isruta==false" label="Inactiva"></v-radio>
                            </v-radio-group>
                        
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <v-row>
                            <v-col md="1">
                                <v-icon color="#40E0D0">mdi-storefront-outline </v-icon> 
                            </v-col>
                            <v-col>
                                <div class="" style="color:; font-size:16px;">
                                    {{item.name}}
                                </div>
                            </v-col>
                        </v-row>
                        
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
        <!-- {{sucursales}} -->
        
        
            
        
    </div>
</template>
<style>
    .v-card .v-data-table-header th[role=columnheader] {
        font-size: 13px !important;
        text-align: center !important;
    }
 
</style>
<script>
import axios from 'axios';
import config from '../../json/config.json'

export default {
    data:() =>({
        rutastotal:[],
        addSucursal:false,
        defaultItem:{
            name:'',
            url_maps:''
        },
        dialog:false,
        editedIndex: -1,
        sucursales:[],
        rutas:[],
        search:'',
        headers:[
            {text:'Nombre', value:'name'},
            {text:'Organización', value:'ad_org_id'},
            {text:'Rutas',value:'rutas'},
            {text:'URL', value:'url_maps'},
            {text:'Value', value:'value'},
            {text:'Horario', value:'horario'},
            {text:'Activo', value:'isactive'},
            {text:'Ruta', value:'isruta'},
            {text:'Acciones',value:'acciones', sortable: false}
        ],
        editeditem:{
            name:'',
            url_maps:'',
        },
        editeditem2:{},
        agregarItem:{},
        agregarItem2:{},
        nuevaRuta:{},
        isEdit:false,
        edit:false,
        dialogruta:false,
        editarSucursal:[],
        agregarSucursal:[],
    }),
    methods:{
        async mostrarSucursales(){
            this.sucursales = await axios.get(config.apiAmoresens + "/sucursal/get_auth",
            { headers:{token: this.$cookie.get('token')}})
            .then(res=>{
                // console.log('sucursales' ,res.data.data);
                this.sucursales = res.data.data
                return res.data.data;
            }).catch(err=>{
                console.log(err);
            });
        },
        async crudedit(editeditem,editeditem2){
            this.editeditem = editeditem;
            // this.editeditem2 = editeditem.rutas;
            this.sucursales._id = editeditem2._id;
            this.sucursales._id = editeditem._id;
            this.sucursales.name = editeditem.name;
            this.sucursales.url_maps = editeditem.url_maps;
            this.sucursales.hr_max = editeditem2.hr_max;
            this.sucursales.numero_ruta = editeditem2.numero_ruta;
            // console.log(this.editeditem2,'sucursales');
            this.editarSucursales(editeditem._id,editeditem2);
        },
        async editarSucursales(_id){
            let valid = this.$refs.editForm1.validate();
            if(valid){

                for (let i = 0; i < this.editeditem.rutas.length; i++) {
                    this.rutastotal = this.editeditem.rutas[i];
                    // console.log(this.rutastotal,'editar');
                    this.editeditem._id2 = _id;
                    this.editarSucursal = await axios.put(config.apiAmoresens + `/sucursal/edit_sucursal/${_id}`,
                    this.editeditem,
                    { headers :{token:this.$cookie.get('token')},params:{rutastotal:this.rutastotal}})          
                    .then(res=>{
                        return res.data
                    })  
                    .catch(err =>{
                        console.log(err);
                    });
                }
                this.dialog = false;
                this.mostrarSucursales();
            }
        },
        editItem (item) {
            this.editedIndex = this.sucursales.indexOf(item)
            this.editeditem = Object.assign({}, item)
            this.dialog = true
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editeditem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        async agregarSucursales(){ 
            let valid = this.$refs.agregarform.validate();
            if(valid){
                await axios.post(config.apiAmoresens + '/sucursal/agregarSucursal',
                this.agregarItem,{ headers :{token:this.$cookie.get('token')},params:{agregarItem2:this.agregarItem2}})
                .then(res=>{return res.data;})
                .catch(err=>{console.log(err);})
                this.addSucursal = false
                this.mostrarSucursales();

            }
        },
        async agregarRuta(){
            console.log(this.editeditem);
            this.nuevaRuta._id = this.editeditem._id;
            await axios.post(config.apiAmoresens + '/sucursal/agregarruta',
            this.nuevaRuta,{ headers :{token:this.$cookie.get('token')}})
            .then(res=>{return res.data;})
            .catch(err=>{return err })
            this.dialogruta = false
            this.mostrarSucursales();
            this.$refs.addForm.reset();

        }
        //:::::::::::::: EDITAR POR CAMPOS
        // editar_diaLlegada (editeditem,editeditem2) {
        //         this.editarSucursales_campo(editeditem._id,editeditem2);
        // },
        // editar_diaPreparación (editeditem,editeditem2) {
        //         this.editarSucursales_campo(editeditem._id,editeditem2);
        // },
        // editar_diaSalida (editeditem,editeditem2) {
        //         this.editarSucursales_campo(editeditem._id,editeditem2);
        // },
        // editar_horas (editeditem,editeditem2) {
        //         this.editarSucursales_campo(editeditem._id,editeditem2);
        // },
        // async editarSucursales_campo(_id){
        //     console.log(this.editeditem.rutas,'prim');
        //     for (let i = 0; i < this.editeditem.rutas.length; i++) {
        //         this.rutastotal = this.editeditem.rutas[i];
        //         console.log(this.rutastotal,'editar');
                
        //     }
        //     this.editarSucursal = await axios.put(config.apiAmoresens + `/sucursal/edit_sucursal_campo/${_id}`,
        //     this.editeditem, 
        //     { headers :{token:this.$cookie.get('token')},params:{rutastotal:this.rutastotal}})          
        //     .then(res=>{return res.data
        //     })  
        //     .catch(err =>{return err
        //     })
        //     // this.dialog = false
        //     this.mostrarSucursales();
        // },
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Sucursal' : 'Editar Sucursal'
      },
      icon () {
        return this.icons[this.iconIndex]
      },
    },
    async created(){
        this.mostrarSucursales();
        this.isLoad = true;
        this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{return res.data;})
            .catch(err=>{return err;});     
            if (this.user.status == "success") {
            this.user = this.user.user; 
            this.isLogged = true;
            }else if(this.user.status == "unauthorized"){ 
            this.$router.push('/shop/admin'+config.matchAdmin+'/login');
            this.isLogged = false;
            } 
            
        this.isLoad = false;
        // this.mostrarRutas();
    }
}
</script>   